import React from "react";
import Avatar from 'react-avatar';

interface IProfileCard {
    name: string
}

const containerStyle: React.CSSProperties = {
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'left'
}

const avatarStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',

    width: '40px',
    height: '40px',
    borderRadius: '20px',
    //objectFit: 'cover',
    overflow: 'hidden',    // Обрезать содержимое за пределами контейнера
    display: 'flex',       // Выравнивание содержимого
    alignItems: 'center',  // Центрирование по вертикали
    justifyContent: 'center', // Центрирование по горизонтали
    backgroundColor: 'teal',
    fontSize: '14px'
}

const textContainerStyle: React.CSSProperties = {
    marginLeft: '16px'
}

const welcomeTextStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '100',
    fontSize: '16px'
}

const nameStyle: React.CSSProperties = {
    ...welcomeTextStyle,
    fontWeight: '600'
}

function ProfileCard(profile: IProfileCard) {





    const handleProfileClick = () => {
        // navigate('/profile'); // Переход на страницу /profile
    };

    return (
        <div onClick={handleProfileClick} style={containerStyle}>
            {/* <img style={avatarStyle} src="https://img.freepik.com/free-photo/handsome-bearded-guy-posing-against-the-white-wall_273609-20597.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1725408000&semt=ais_hybrid"></img> */}
            <Avatar style={avatarStyle}
                name={`Бейбарыс`}
                size="50"
                round={true}
                color="#D9D9D9"
                fgColor="#000000"
            />
            <div style={textContainerStyle}>
                <div style={welcomeTextStyle}>Добро пожаловать</div>
                <div style={nameStyle}>{profile.name}</div>
            </div>

        </div>
    )
}

export default ProfileCard
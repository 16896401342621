import { ItemList } from './item-list/ItemList.js';
import { FilterList } from './filter/FilterList.tsx';
import { Heading } from '../common/Heading.js';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { getProductFolders, getProductsByFolder } from '../../services/moysklad.ts';
import { FaShoppingCart } from 'react-icons/fa'; // Добавляем иконку корзины
import ProfileCard from '../profile/ProfileCard.tsx';
import Cart from '../cart/Cart.tsx';
import ProductDetail from '../product-details/ProductDetail.tsx';


function Shop() {

    const [items, setItems] = useState([]);
    const [filters, setFilters] = useState([])
    const [cartCount, setCartCount] = useState(0); // Добавляем стейт для количества товаров в корзине
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const openCart = () => {
        setIsCartOpen(true);
    };

    const closeCart = () => {
        setIsCartOpen(false);
    };

    const updateCartCount = () => {
        const cartItems = JSON.parse(localStorage.getItem('cart') || '[]');
        setCartCount(cartItems.length);
    };



    useEffect(() => {
        // Функция для загрузки данных

        async function fetchProductGroups() {
            const productGroups = await getProductFolders();
            setFilters(productGroups);
            console.log("groups");

            console.log(productGroups)

        }

        fetchProductGroups();
        updateCartCount(); // Обновляем количество товаров в корзине при монтировании компонента





    }, []);


    async function fetchItems(filter = "https://api.moysklad.ru/api/remap/1.2/entity/productfolder/936ca7f4-651d-11ef-0a80-11b4000c68f2") {


        try {
            const itemsFromApi = await getProductsByFolder(filter);


            setItems(itemsFromApi); // Обновление состояния items после получения данных

        } catch (error) {
            console.error("Error fetching items:", error);
        }
    }

    useEffect(() => {
        fetchItems();
    }, []);

    const styles = {
        mainPage: {
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
        },
        content: {
            height: 'calc(100vh - 240px)',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            marginBottom: '10px'
        },
        headingContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        cartIcon: {
            color: '#fff',
            fontSize: '24px',
            cursor: 'pointer'
        },
        cartIconContainer: {
            position: 'relative'
        },
        cartBadge: {
            position: 'absolute',
            top: '-8px',
            right: '-8px',
            backgroundColor: '#ffeb3b', // Желтый цвет значка
            borderRadius: '50%',
            width: '18px',
            height: '18px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            color: '#000'
        }
    }

    function handleChangeFilter(filter) {
        fetchItems(filter)
    }

    function handleProductClick(product) {
        setSelectedProduct(product); // Устанавливаем выбранный продукт
    }

    function handleBackToShop() {
        setSelectedProduct(null); // Возвращаемся к магазину
    }

    return (
        <div style={styles.mainPage}>
            <div style={styles.headingContainer}>
                <Heading name={selectedProduct ? 'Детали продукта' : 'Магазин'} />
                <div style={styles.cartIconContainer} onClick={openCart}>
                    <FaShoppingCart style={styles.cartIcon} />
                    {cartCount > 0 && (
                        <div style={styles.cartBadge}>
                            {cartCount}
                        </div>
                    )}
                </div>
            </div>

            {/* Условный рендер: показываем либо список товаров, либо детали продукта */}
            {!selectedProduct ? (
                <>
                    <FilterList
                        filters={filters}
                        onChangeFilter={(filter) => handleChangeFilter(filter)}
                    />
                    <ItemList
                        style={styles.content}
                        items={items}
                        onProductClick={handleProductClick} // Передаем функцию для клика по продукту
                    />
                </>
            ) : (
                <ProductDetail
                    product={selectedProduct}
                    onBack={handleBackToShop} // Передаем функцию для возврата в магазин
                />
            )}
        </div>
    );
}

export default Shop;

import React, { useEffect, useState } from "react";
import { SlArrowRight } from "react-icons/sl";
import { getLeaderboards, IDriverProfile } from "../../services/fleet.ts";
import LeaderboardElement from "./LeaderboardElement.tsx";
import ProfileCard from "../profile/ProfileCard.tsx";
import { Heading } from "../common/Heading.js";

const containerStyle: React.CSSProperties = {
    backgroundColor: 'black',
    color: 'white',






}

const staticTextStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '500',
    fontSize: '16px',
}

const moreButton: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '300',
    color: '#B1B1B1',
    fontSize: '12px',
    gap: '4px'
}

const arrowIcon: React.CSSProperties = {
    height: '10px'
}


function Leaderboards() {




    const [leaderboard, setLeaderboard] = useState<IDriverProfile[]>([])


    useEffect(() => {

        async function fetchLeaderboard() {
            const drivers = await getLeaderboards();
            setLeaderboard(drivers)

        }

        fetchLeaderboard();


    }, []);

    return (
        <div>

            <div style={containerStyle}>

                <Heading name={"Топ 10"}></Heading>


                <div style={{ paddingTop: "20px" }}>

                    {leaderboard.map((driver) => (
                        <LeaderboardElement
                            name={driver.driver_profile.first_name}
                            car={driver.car.brand + " " + driver.car.model}
                            balance={Number(driver.summary.incomes)}
                            last_name={driver.driver_profile.last_name}
                        ></LeaderboardElement>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Leaderboards
import React from "react";
import { FaTrash } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";

const container: React.CSSProperties = {
    display: 'flex',
    gap: '5px',
}

const trashButton: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    border: '1px solid #BD4848',
    borderRadius: '3px',
    height: '25px',
    width: '25px',
    boxSizing: 'border-box',
    color: '#BD4848'
}

const plusButton: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    border: '1px solid white',
    borderRadius: '3px',
    height: '25px',
    width: '25px',
    boxSizing: 'border-box'
}

const counter: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    border: '1px solid white',
    borderRadius: '3px',
    height: '25px',
    width: '50px',
    boxSizing: 'border-box',
    fontSize: '12px'
}

interface IAmountPanel {
    amount: number;
    onIncrement: () => void,
    onDecrement: () => void,
    onDelete: () => void
}

function ItemAmountPanel({ amount, onIncrement, onDecrement, onDelete }: IAmountPanel) {
    return (
        <div style={container}>
            {amount <= 1 ? (
                <div style={trashButton} onClick={onDelete}>
                    <FaTrash size={'12'} />
                </div>
            ) : (
                <div style={plusButton} onClick={onDecrement}>
                    <FiMinus size={'14'} />
                </div>
            )}
            <div style={counter}>
                {amount} шт
            </div>
            <div style={plusButton} onClick={onIncrement}>
                <FiPlus size={'14'} />
            </div>
        </div>
    );
}

export default ItemAmountPanel;

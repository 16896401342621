import React from "react";
import { Heading } from "../../components/common/Heading";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOneProduct, getProductImage, IProduct } from "../../services/moysklad.ts";
import { FaArrowLeft } from 'react-icons/fa';

interface IProductDetail {
    id: string
}

function ProductDetail() {

    const [imgSrc, setImgSrc] = useState("");
    const [priceNumString, setPriceString] = useState("");
    const [inCart, setInCart] = useState(false);


    const [item, setItem] = useState<IProduct | null>(null);
    const { id } = useParams();
    const location = useLocation();

    const handleAddToCart = () => {
        if (!inCart) {
            const tempItem = { id: item?.id, name: item?.name, price: item?.buyPrice.value, amount: 1, totalPrice: item?.buyPrice.value };
            const cartItems = localStorage.getItem('cart');
            const cart = cartItems ? JSON.parse(cartItems) : [];
            localStorage.setItem('cart', JSON.stringify([...cart, tempItem]));
            setInCart(true);
        }
    };

    const handleRedirectToCart = () => {
        // navigate('/cart');
    };

    useEffect(() => {
        async function fetchProductDetails() {
            const item = await getOneProduct(id);
            setItem(item);

            const cartItems = localStorage.getItem('cart');
            if (cartItems) {
                const cart = JSON.parse(cartItems) || [];
                const itemInCart = cart.some((cartItem: any) => cartItem.id === item.id);
                setInCart(itemInCart);
            }
        }

        fetchProductDetails();
    }, [id]);

    useEffect(() => {
        async function getImageLink() {
            if (item && item.images && item.images.meta && item.images.meta.href) {
                const image = await getProductImage(item.images.meta.href);
                setImgSrc(image);
            }
        }

        getImageLink();
    }, [item]);

    useEffect(() => {
        if (item && item.buyPrice && item.buyPrice.value) {
            const formattedNumber = new Intl.NumberFormat('en-US').format(item.buyPrice.value);
            const formattedWithSpaces = formattedNumber.replace(/,/g, ' ');
            setPriceString(formattedWithSpaces);
        }
    }, [item]);

    const mainPage: React.CSSProperties = {
        backgroundColor: 'black',
        height: '100vh',
        overflow: 'hidden',
        padding: '20px',
        paddingTop: '30px',
        display: 'flex',
        flexDirection: 'column'
    };

    const content = {
        height: 'calc(100vh - 240px)',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        marginBottom: '10px'
    };

    const img1: React.CSSProperties = {
        width: '100vw',
        height: 'auto',
        objectFit: 'cover'
    };

    const img: React.CSSProperties = {
        width: 'auto',
        objectFit: 'cover',
        borderRadius: '10px',
        height: '300px'
    };

    const heading: React.CSSProperties = {
        fontFamily: 'Rubik, sans-serif',
        fontSize: '24px',
        fontWeight: '700',
        color: '#ffffff',
        marginLeft: '14px',
        marginBottom: '8px',
        marginTop: '24px',
    };

    const price = {
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: '500',
        color: '#FFFFFF',
        marginLeft: '14px',
        marginBottom: '0',
        marginTop: '8px',
        width: '100%'
    };

    const description = {
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        fontWeight: '500',
        color: '#B1B1B1',
        marginLeft: '14px',
        marginBottom: '0',
        marginTop: '16px',
        width: '100%'
    };

    const divider = {
        width: '100%',
        height: '1px',
        backgroundColor: '#ddd',
        marginTop: '30px',
    };

    const misc = {
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: '500',
        color: '#FFFFFF',
        marginLeft: '14px',
        marginBottom: '0',
        marginTop: '24px',
        width: '100%'
    };

    const misc2 = {
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: '400',
        color: '#FFFFFF',
        marginLeft: '14px',
        marginBottom: '0',
        marginTop: '18px',
        width: '100%',
        fontStyle: 'italic',
    };

    const addToCartButton: React.CSSProperties = {
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        color: '#fff',
        border: 'none',
        borderRadius: '30px',
        cursor: 'pointer',
        width: '80%',
        fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        fontWeight: '600',
        padding: '20px 0',
        backgroundColor: inCart ? '#00FFB2' : '#FF8A00',
    };

    const backButton: React.CSSProperties = {
        alignSelf: 'flex-start',
        marginBottom: '20px',
        marginLeft: '10px',
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
    };

    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    if (vw > 500) return (
        <div style={mainPage}>
            <Heading name={'Этот сайт работает только на мобильных устройствах'} />
        </div>
    );

    if (item === null) {
        return <div>Loading...</div>;
    }

    return (
        <div style={mainPage}>
            {/* <div style={backButton} onClick={() => navigate(-1)}>
                <FaArrowLeft color="white" size={32} />
            </div> */}
            <img style={img} src={imgSrc} alt={item.name} />
            <h1 style={heading}>{item.name}</h1>
            <p style={price}>{priceNumString}₸</p>
            <p style={description}>Колесо RX-210 Offroad разработано для использования в самых сложных внедорожных условиях</p>
            <div style={divider}></div>
            <p style={misc}>Подходит для:</p>
            <ul style={misc2}>
                <li>Lightning McQueen 3</li>
                <li>Lightning McQueen 3</li>
            </ul>
            <button
                onClick={inCart ? handleRedirectToCart : handleAddToCart}
                style={addToCartButton}
            >
                {inCart ? 'В корзине' : 'Добавить в корзину'}
            </button>
        </div>
    );
}

export default ProductDetail;

import { Link } from "react-router-dom";
import React from "react";
import { useEffect, useState } from "react";

import { getProductImage } from "../../../services/moysklad.ts";

import { formatPriceFromMoySklad } from "../../../utils/formatPrice.ts"

export function Item({
    item
}) {

    const [imgSrc, setImgSrc] = useState("")

    const styles = {
        container: {
            //width: '45vw',
            flex: '1 1 calc(40% - 10px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderRadius: '8px',
            margin: '0',
            boxSizing: 'border-box',
            textDecoration: 'none'
        },
        image: {
            height: '140px',
            width: '100%',
            borderRadius: '8px',
            objectFit: 'cover',
            marginBottom: '8px',
        },
        name: {
            fontFamily: 'Inter, sans-serif', // Применение шрифта Inter
            fontSize: '11px', // Размер шрифта 11px
            fontWeight: '500',
            color: '#B1B1B1', // Цвет текста #B1B1B1
            marginLeft: '14px',
            marginBottom: '0',
            marginTop: '8px',
            width: '100%'
        },
        price: {
            fontFamily: 'Rubik, sans-serif', // Шрифт Rubik для цены
            fontSize: '20px',
            fontWeight: '700',
            color: '#ffffff',
            marginLeft: '14px',
            marginBottom: '8px',
            marginTop: '8px',
        },
    };

    useEffect(() => {
        // Функция для загрузки данных

        async function getImageLink() {
            const image = await getProductImage(item.images.meta.href);
            setImgSrc(image)
        }

        getImageLink();




    }, [item]);

    return (

        <div>
            <img src={imgSrc} alt={item.name} style={styles.image} />
            <h3 style={styles.name}>{item.name}</h3>
            <span style={styles.price}>{formatPriceFromMoySklad(item.buyPrice.value)}</span>
        </div>

    );
}
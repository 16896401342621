import React, { useEffect, useState } from "react";
import { SlArrowRight } from "react-icons/sl";
import { getLeaderboards, IDriverProfile } from "../../services/fleet.ts";
import LeaderboardElement from "../leaderboard/LeaderboardElement.tsx";




const containerStyle: React.CSSProperties = {
    backgroundColor: 'black',
    color: 'white',



    paddingTop: '20px',


}

const headerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px'
}

const staticTextStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '300',
    fontSize: '24px',
}

const moreButton: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '300',
    color: '#B1B1B1',
    fontSize: '12px',
    gap: '4px'
}

const arrowIcon: React.CSSProperties = {
    height: '10px'
}


function Leaderboards(props: { setState: () => void }) {

    const [leaderboard, setLeaderboard] = useState<IDriverProfile[]>([])



    const handleDetailsClick = () => {
        props.setState()
    };


    useEffect(() => {

        async function fetchLeaderboard() {
            const drivers = await getLeaderboards();
            setLeaderboard(drivers.slice(0, 3))

        }

        fetchLeaderboard();


    }, []);

    return (
        <div style={containerStyle}>
            <div style={headerStyle}>
                <div style={staticTextStyle}>Топ 3 по деньгам</div>
                <div onClick={handleDetailsClick} style={moreButton}>
                    <div>Подробнее</div>
                    <SlArrowRight style={arrowIcon} />
                </div>

            </div>
            {leaderboard.map((driver) => (
                <LeaderboardElement
                    name={driver.driver_profile.first_name}
                    car={driver.car.brand + " " + driver.car.model}
                    balance={Number(driver.summary.incomes)}
                    last_name={driver.driver_profile.last_name}
                ></LeaderboardElement>
            ))}
        </div>
    )
}

export default Leaderboards
import React from 'react'
import { useState } from "react";
import { FilterBadge } from "./FilterBadge.tsx";
import { IFilter, IFilterList } from "./interfaces.ts"

export function FilterList(filterList: IFilterList) {

    const [activeFilter, setActiveFilter] = useState("Мопеды")

    const handleFilterClick = (filter: IFilter) => {
        console.log(filter)
        setActiveFilter(filter.name);
        filterList.onChangeFilter(filter.meta.href)
    };

    const style: React.CSSProperties = {
        display: 'flex',
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollbarWidth: 'none',
        alignItems: 'center',
        minHeight: '40px',
        lineHeight: '1.5',
        padding: '20px 0'
    }

    return (
        <div style={style}>


            {filterList.filters.map((item, index) => (
                <FilterBadge
                    {...item}
                    key={index}
                    name={item.name}
                    enabled={activeFilter === item.name}
                    onClick={() => { handleFilterClick(item) }}
                    id={item.id}
                ></FilterBadge>
            ))}


        </div>
    );
}
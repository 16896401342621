import { Item } from "./Item"

export function ItemList({ items }) {
    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            justifyContent: 'space-between',
            overflowY: 'auto', // Вертикальный скролл
            overflowX: 'hidden',
            flexGrow: 1, // Занимает оставшуюся высоту
            scrollbarWidth: 'none', // Для Firefox
        },
        item: {
            width: 'calc(50% - 10px)', // Фиксированная ширина для каждого элемента
            flexGrow: 0, // Элементы не будут растягиваться
            flexShrink: 0,
            boxSizing: 'border-box',
        }
    }

    return (
        <div style={styles.container}>
            {items.map((item, index) => (
                <div style={styles.item} key={index}>
                    <Item
                        item={item}
                    />
                </div>
            ))}
        </div>
    )
}

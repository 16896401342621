import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Shop from './components/shop/Shop';
import ProductDetail from './components/product-details/ProductDetail.tsx';
import "./index.css"
import Profile from './components/profile/Profile.tsx';
import Cart from './components/cart/Cart.tsx';
import Leaderboards from './components/leaderboard/Leaderboard.tsx';
import App from './App.tsx';


// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: (
//       <Shop></Shop>
//     ),
//   },
//   {
//     path: "/:id",
//     element: <ProductDetail></ProductDetail>,
//   }, ,
//   {
//     path: "/profile",
//     element: <Profile></Profile>,
//   },
//   {
//     path: "/cart",
//     element: <Cart></Cart>,
//   },
//   {
//     path: "/leaderboards",
//     element: <Leaderboards></Leaderboards>,
//   },
// ]);

createRoot(document.getElementById("root")).render(
  //<RouterProvider router={router} />
  <App></App>
);


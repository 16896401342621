import React, { useEffect, useState } from 'react';
import { Heading } from '../common/Heading.js';
import CartItem from './CartItem.tsx';
import { formatPrice } from '../../utils/formatPrice.ts';
import { getDriverProfile, makeOrder } from '../../services/fleet.ts';

interface ICartItems {
    amount: number;
    id: string;
    name: string;
    price: number;
}

function Cart() {
    const [totalAmount, setTotalAmount] = useState(0);
    const [cartItems, setCartItems] = useState<Array<ICartItems>>([]);
    const [driverBalance, setDriverBalance] = useState(1000); // Example balance, replace with actual data
    const [isPaid, setIsPaid] = useState(false); // State to track payment status

    useEffect(() => {
        const fetchDriverProfile = async () => {
            try {
                const profile = await getDriverProfile('something'); // Use the appropriate phone number
                setDriverBalance(Number(profile.accounts[0].balance)); // Adjust this based on your profile structure
            } catch (error) {
                console.error('Error fetching driver profile:', error);
            }
        };

        fetchDriverProfile();
    }, []);

    useEffect(() => {
        const tempCart = localStorage.getItem('cart');
        if (tempCart) {
            const cart = JSON.parse(tempCart) || [];
            setCartItems(cart);
            calculateTotal(cart);
        }
    }, []);

    const calculateTotal = (items: ICartItems[]) => {
        const total = items.reduce((sum, item) => sum + item.price * item.amount, 0);
        setTotalAmount(total);
    };

    const handleIncrement = (id: string) => {
        const updatedCart = cartItems.map(item =>
            item.id === id ? { ...item, amount: item.amount + 1 } : item
        );
        setCartItems(updatedCart);
        calculateTotal(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const handleDecrement = (id: string) => {
        const updatedCart = cartItems.map(item =>
            item.id === id && item.amount > 1 ? { ...item, amount: item.amount - 1 } : item
        );
        setCartItems(updatedCart);
        calculateTotal(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const handleDelete = (id: string) => {
        const updatedCart = cartItems.filter(item => item.id !== id);
        setCartItems(updatedCart);
        calculateTotal(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const handlePayment = async () => {
        if (isBalanceSufficient) {
            const driver = await getDriverProfile("something");
            await makeOrder(-totalAmount, driver.driver_profile.id);
            setIsPaid(true); // Set payment status to true
            localStorage.removeItem('cart'); // Clear the cart from local storage
        }
    };

    const isBalanceSufficient = totalAmount <= driverBalance;

    const purchaseButtonContainer: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '20px',
        marginTop: 'auto',
    };

    const purchaseButton: React.CSSProperties = {
        width: '75%',
        height: '60px',
        borderRadius: '40px',
        backgroundColor: isBalanceSufficient ? "#DD5C00" : "#A9A9A9",
        color: "#FFFFFF",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        fontSize: '18px',
        cursor: isBalanceSufficient ? 'pointer' : 'not-allowed',
    };

    const pageStyle: React.CSSProperties = {




        display: 'flex',
        flexDirection: 'column',
    };

    return (
        <div style={pageStyle}>
            <Heading name="Корзина" />
            {cartItems.length > 0 ? (
                cartItems.map(item => (
                    <CartItem
                        key={item.id}
                        amount={item.amount}
                        name={item.name}
                        id={item.id}
                        price={item.price}
                        onIncrement={() => handleIncrement(item.id)}
                        onDecrement={() => handleDecrement(item.id)}
                        onDelete={() => handleDelete(item.id)}
                    />
                ))
            ) : (
                <p>Ваша корзина пуста</p>
            )}

            <div style={purchaseButtonContainer} onClick={handlePayment}>
                <div style={purchaseButton}>
                    {isPaid ? 'Успешно оплачено' : (isBalanceSufficient ? `Оплатить с баланса ${formatPrice(totalAmount)} ₸` : 'Недостаточно средств')}
                </div>
            </div>
        </div>
    );
}

export default Cart;

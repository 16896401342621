import React, { useEffect, useState } from "react";
import { getProductImageById } from "../../services/moysklad.ts";
import ItemAmountPanel from "./ItemAmountPanel.tsx";
import { formatPriceFromMoySklad } from "../../utils/formatPrice.ts";

interface ICartItem {
    id: string,
    name: string,
    price: number,
    amount: number,
    onIncrement: () => void,
    onDecrement: () => void,
    onDelete: () => void;
}

const card: React.CSSProperties = {
    padding: '0',
    display: 'flex',
    color: 'white',
    borderRadius: '20px',
    marginBottom: '10px',
    alignItems: 'center',
    width: 'calc(100% - 20px)'
}

const image: React.CSSProperties = {
    borderRadius: '10px',
    width: '100px',
    height: '80px',
    objectFit: 'cover',
}

const textContainer: React.CSSProperties = {
    marginLeft: '20px',
}

const priceStyle: React.CSSProperties = {
    fontFamily: 'Rubik, sans-serif',
    fontWeight: '600',
    fontSize: '18px',
}

const nameStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    color: '#C3C3C3',
    fontWeight: '400',
    fontSize: '14px',
    marginBottom: '5px'
}

function CartItem({ id, name, price, amount, onIncrement, onDecrement, onDelete }: ICartItem) {
    const [imgSrc, setImgSrc] = useState("");

    useEffect(() => {
        async function getImageLink() {
            const image = await getProductImageById(id);
            setImgSrc(image);
        }
        getImageLink();
    }, [id]);

    const totalPrice = price * amount;

    return (
        <div style={card}>
            <img style={image} src={imgSrc} alt={name} />
            <div style={textContainer}>
                <div style={priceStyle}>{formatPriceFromMoySklad(totalPrice)} ₸</div>
                <div style={nameStyle}>{name}</div>
                <ItemAmountPanel
                    onDecrement={onDecrement}
                    onIncrement={onIncrement}
                    onDelete={onDelete}
                    amount={amount}
                />
            </div>
        </div>
    );
}

export default CartItem;

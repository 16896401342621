import React from 'react'
import { useState } from 'react';
import { IFilter } from './interfaces'

export function FilterBadge(filter: IFilter) {

    const styles = {
        badge: {
            backgroundColor: '#353535',
            color: 'white',
            padding: '8px',
            paddingLeft: '15px',
            paddingRight: '15px',
            fontSize: '13px',
            fontFamily: 'Inter, sans-serif',
            display: 'inline-block',
            borderRadius: '20px',
            marginRight: '12px'
        },
        enabledBadge: {
            backgroundColor: '#ffffff',
            color: 'black',
            padding: '8px',
            paddingLeft: '15px',
            paddingRight: '15px',
            fontSize: '13px',
            fontFamily: 'Inter, sans-serif',
            display: 'inline-block',
            borderRadius: '20px',
            marginRight: '12px'
        }

    };



    return (
        <div
            style={filter.enabled ? styles.enabledBadge : styles.badge}
            onClick={filter.onClick}
        >
            <span>{filter.name}</span>
        </div>
    );



}
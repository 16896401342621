import axios from "axios";

const DOMAIN_NAME = process.env.DOMAIN_NAME

type AccountType = string;

export interface IDriverProfileAccount {
    balance: string,
    balance_limit: string,
    currency: string,
    id: string,
    type: AccountType
}

type ColorEnum = string;

interface IVehicle {
    id: string,
    amenities: Array<string>,
    brand: string,
    callsign: string,
    category: Array<string>,
    color: ColorEnum,
    model: string,
    number: string,
    registration_cert: string,
    status: string,
    vin: string,
    year: number
}

type DriverStatus = string;

interface IDriverProfileCurrentStatus {
    status: DriverStatus,
    status_updated_at: string
}

interface IDriverProfileModel {
    check_message: string,
    comment: string,
    created_date: string,
    driver_licence: object,
    first_name: string,
    has_contract_issue: boolean,
    id: string,
    is_selfemployed: boolean,
    last_name: string,
    middle_name: string,
    park_id: string,
    phones: Array<string>,
    work_rule_id: string,
    work_status: string
}

export interface IDriverProfile {
    accounts: Array<IDriverProfileAccount>,
    car: IVehicle,
    current_status: IDriverProfileCurrentStatus,
    driver_profile: IDriverProfileModel
    summary: {
        incomes: number,
        expenses: number
    }
}

export interface IGetSummary {
    expenses: number,
    incomes: number,
}

export async function getDriverProfile(phone: string): Promise<IDriverProfile> {
    const result = await axios.get(`https://${DOMAIN_NAME}/api/driver-by-phone?phone=77053098474`)

    return result.data
}

export async function getLeaderboards(): Promise<IDriverProfile[]> {
    const result = await axios.get(`https://${DOMAIN_NAME}/api/drivers`)

    return result.data
}

export async function getTotals(): Promise<IGetSummary> {
    const result = await axios.get(`https://${DOMAIN_NAME}/api/driver/transactions/summary?phone=77053098474`)

    return result.data
}

export async function makeOrder(amount: number, driverId: string) {
    const body = {
        amount: amount / 100,
        driverId
    }
    const result = await axios.post(`https://${DOMAIN_NAME}/api/shop/order`, body)
    console.log(result.data)
    return result.data
}
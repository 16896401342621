import React from "react";
import { FaHome } from "react-icons/fa";
import { MdLeaderboard } from "react-icons/md";
import { FaShop } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";

export type State = "HOME" | "LEADERBOARDS" | "SHOP" | "CART"

const HEIGHT = 80
const PADDING = 5
export const NAVBAR_HEIGHT = HEIGHT + 2 * PADDING

function NavBar(props: { state: State, setState: (page: State) => void }) {

    const navBarPadding = 40

    const navBarContainer: React.CSSProperties = {
        position: "fixed",
        bottom: "0",
        left: "0",
        padding: `${PADDING}px ${navBarPadding}px`,
        height: `${HEIGHT}px`,
        width: `calc(100vw - ${2 * navBarPadding}px)`,
        display: "flex",
        backgroundColor: "#303030",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "20px",
        color: "#FFFFFF",
        zIndex: "100",
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px'
    }

    const navButtonContainer: React.CSSProperties = {
        width: "55px",
        height: "60px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
    }

    const activeNavButtonContainer: React.CSSProperties = {
        ...navButtonContainer,
        color: "#FF8A00",
    }

    function buttonStateByPage(state: State) {
        return (props.state == state) ? activeNavButtonContainer : navButtonContainer
    }


    return (
        <div style={navBarContainer}>
            <div style={buttonStateByPage("HOME")} onClick={() => props.setState("HOME")}>
                <FaHome size={40}></FaHome>
                <div>Главная</div>
            </div>
            <div style={buttonStateByPage("LEADERBOARDS")} onClick={() => props.setState("LEADERBOARDS")}>
                <MdLeaderboard size={40}></MdLeaderboard>
                <div>Рейтинг</div>
            </div>
            <div style={buttonStateByPage("SHOP")} onClick={() => props.setState("SHOP")}>
                <FaShop size={40}></FaShop>
                <div>Магазин</div>
            </div>
            <div style={buttonStateByPage("CART")} onClick={() => props.setState("CART")}>
                <FaShoppingCart size={40}></FaShoppingCart>
                <div> Корзина</div>
            </div>
        </div>
    )
}


export default NavBar
import React from "react";


const containerStyle: React.CSSProperties = {
    backgroundColor: 'black',
    color: 'white',



    paddingTop: '30px',


}

const staticTextStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '500',
    fontSize: '15px',
    color: '#B1B1B1',
    marginBottom: '8px'
}

const balanceStyle: React.CSSProperties = {
    fontFamily: 'Rubik, sans-serif',
    fontWeight: '400',
    fontSize: '48px',
    marginBottom: '12px'
}

const feeBadge: React.CSSProperties = {
    fontFamily: 'Rubik, sans-serif',

    backgroundColor: '#353535',
    display: 'inline-block', // Добавлено для ограничения ширины текста

    padding: '10px 15px',
    borderRadius: '100px'

}



// const styles = {
//     container: React.CSSProperties {

//     },
//     avatar: {

//     },
//     name: {

//     },
//     welcomeText: {

//     }
// }

function BalanceContainer(balanceData: { balance: string }) {
    return (
        <div style={containerStyle}>
            <div style={staticTextStyle}>БАЛАНС</div>
            <div style={balanceStyle}>{balanceData.balance}</div>
            <div style={feeBadge}>2% комиссии</div>
        </div>
    )
}

export default BalanceContainer
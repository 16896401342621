import React from "react";
import { useState, useEffect } from "react";
import { getDriverProfile, IDriverProfile } from "../../services/fleet.ts";
import ProfileCard from "./ProfileCard.tsx";
import BalanceContainer from "./BalanceContainer.tsx";
import PersonalStats from "./PersonalStats.tsx";
import ShopPromo from "./ShopPromo.tsx";
import Leaderboards from "./Leaderboards.tsx";
import { formatBalance } from "../../utils/formatPrice.ts";
import { State } from "../navigation/NavBar.tsx";


function Profile(props: { setState: (page: State) => void }) {

    // здесь нужно как-то получить номер телефона водителя
    // и его аватарку

    const containerStyle: React.CSSProperties = {

    }

    const [profile, setProfile] = useState<IDriverProfile | null>(null)

    useEffect(() => {
        async function fetchDriverProfile() {
            const driverProfile = await getDriverProfile('something')
            setProfile(driverProfile)
        }
        fetchDriverProfile()
    }, [])

    if (!profile) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <div style={containerStyle}>
            <ProfileCard
                name={profile.driver_profile.first_name}
            ></ProfileCard>
            <BalanceContainer balance={formatBalance(Number(profile.accounts[0].balance))}></BalanceContainer>
            <PersonalStats></PersonalStats>
            <ShopPromo setState={() => { props.setState("SHOP") }}></ShopPromo>
            <Leaderboards setState={() => { props.setState("LEADERBOARDS") }}></Leaderboards>
        </div>
    )
}

export default Profile;
